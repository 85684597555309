import React, { useState, useEffect, useCallback, useRef } from "react";
import Highcharts from "highcharts/highstock";
import HighchartsReact from "highcharts-react-official";

import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";

import { format } from "date-fns";
import {
  findInArrayByIndexValue,
  convertToCurrency,
  roundNumber
} from "../../../helpers/functions";

import useResponsive from "../../../hooks/useResponsive";
import useAxios from "../../../hooks/useAxios";
import useErrorHandler from "../../../hooks/useErrorHandler";

import { setPerfChartOptions } from "./perfChartOptions";

// Override drawLegendSymbol for areaspline
// We disable the default legend symbol for the areaspline series and draw lines instead, see onRender function
if (Highcharts.seriesTypes.areaspline) {
  Highcharts.seriesTypes.areaspline.prototype.drawLegendSymbol = function () {
    return;
  };
}

const PerformanceChart = props => {
  const isMobileOrTablet = useResponsive("down", "md");
  const isDesktop = useResponsive("up", "md");
  const isLargeDesktop = useResponsive("up", "xl");
  const { initAxios } = useAxios();
  const axiosGlobalController = useRef(null);
  const errorHandler = useErrorHandler();

  const {
    chartData,
    benchmarkChartData,
    firstTransactionDate,
    movements,
    accountId,
    benchmarkId
  } = props;

  const [state, setState] = useState({ loaded: false });
  const mounted = useRef(false);
  const accountData = useRef([]);
  const accountDataAll = useRef([]);
  const accountDataExtremes = useRef({});
  const benchmarkData = useRef([]);
  const benchmarkDataAll = useRef([]);
  const benchmarkDataExtremes = useRef({});
  const hybridData = useRef([]);
  const hybridDataAll = useRef([]);
  const legendItemClicked = useRef(null);
  const selectedRange = useRef(4);
  const flagDate = useRef(null);
  const totalReturnsBox = useRef(null);
  const returnsBoxContent = useRef("");

  const hasSpecialAccounts = chartData.hasSpecialAccounts;
  const showBenchmarkChart =
    benchmarkId && benchmarkChartData.main?.length ? true : false;

  const accountChartHasData =
    chartData.main?.length > 0 || benchmarkChartData.refAccount?.length > 0;
  const hasDeposits = movements && Object.keys(movements.deposits).length > 0;
  const hasWithdraws = movements && Object.keys(movements.withdraws).length > 0;

  // MARK: Total Returns Box
  const setTotalReturnsBox = useCallback(
    chart => {
      return chart.renderer
        .label(
          returnsBoxContent.current,
          60,
          isMobileOrTablet ? 85 : 50,
          "rect",
          null,
          null,
          true
        )
        .attr({
          fill: "rgba(72, 133, 178, 0.1)", // Background color
          stroke: "rgba(72, 133, 178, 0.5)", // Border color
          "stroke-width": 2,
          padding: isMobileOrTablet ? 5 : 8,
          r: 10, // Border radius
          zIndex: 5
        })
        .css({
          color: "#000",
          fontSize: "10px"
        })
        .add();
    },
    [isMobileOrTablet]
  );

  const setSelectedRange = range => {
    // do we still need this?
    selectedRange.current = range;
  };

  // MARK: toggleWaterMark
  const toggleWaterMark = useCallback(function (
    chart,
    status,
    firstRender = false
  ) {
    const marketValue = chart.series[3]; // Select the series for watermark

    // Remove existing watermark for the series
    if (status === "hide") {
      marketValue.watermark?.destroy();
      return (marketValue.watermark = null);
    } else if (marketValue.watermark && status === "show") {
      marketValue.watermark.destroy();
    }

    const points = marketValue.points; // Access the data points of the series
    if (!points.length) return;
    // Choose the middle point of the series
    const targetPoint = points[Math.floor(points.length / 2)];
    const x = targetPoint.plotX + chart.plotLeft; // Convert to chart coordinates

    // thresholdFix to deal with the issue of the chart changing dimensions when accessed again via legend click
    const thresholdFix = firstRender ? 50 : 75;
    const y = chart.chartHeight - thresholdFix; // Set the y position

    // Add a label for the series
    marketValue.watermark = chart.renderer
      .text("Marknadsvärde", x, y)
      .css({
        color: "#cccccc",
        fontSize: "16px",
        fontWeight: "bold",
        pointerEvents: "none", // Prevent interactions
        textAnchor: "middle",
        userSelect: "none"
      })
      .attr({
        zIndex: 5,
        opacity: 0.3
      })
      .add();
  },
  []);

  // MARK: onChartLoad
  const onChartLoad = useCallback(
    function () {
      const chart = this;
      if (showBenchmarkChart) {
        chart.series.forEach(serie => {
          const serieId = serie.userOptions.id;
          if (
            // Add/Remove according to which chart we want to show as default
            serieId !== "hybrid" &&
            serieId !== "marketValue" &&
            serieId !== "flag" &&
            serieId !== "highcharts-navigator-series"
          ) {
            // hide all series (redraw = false) execept benchmark, start date flag, increaseX flags and navigator series
            serie.setVisible(false, false);
          }

          if (serieId === "hybrid") {
            totalReturnsBox.current = setTotalReturnsBox(chart);
          }
        });
        chart.series[3].setData(hybridData.current, true); // fix yAxis extremes for series[0]
        toggleWaterMark(chart, "show", true);
      }
    },
    [setTotalReturnsBox, showBenchmarkChart, toggleWaterMark]
  );

  // MARK: onRender
  const onRender = useCallback(
    function () {
      var chart = this;

      if (showBenchmarkChart) {
        // Overriding the legend symbol color (can't be done via options)
        chart.series.forEach(function (series) {
          // MARK: Line Colors
          if (
            !series.legendLine &&
            (series.name === "Er utveckling" ||
              series.name === "Modellportföljens utveckling")
          ) {
            // Create legendLine if it doesn't exist
            series.legendLine = chart.renderer
              .path(["M", 0, 11, "L", 16, 11])
              .attr({
                "stroke-width": 2,
                stroke: series.color
              })
              .add(series.legendGroup);
          }

          if (series.name === "Er utveckling") {
            series.legendLine.attr({
              stroke: "#4885B2"
            });
          }
          if (series.name === "Modellportföljens utveckling") {
            series.legendLine.attr({
              stroke: "#1ECBAA"
            });
          }
        });
      }
    },
    [showBenchmarkChart]
  );

  // MARK: afterSetExtremes
  const afterSetExtremes = useCallback(
    function (e) {
      // console.log(`setExtremes triggered by ${e.trigger}`);
      if (e.trigger === undefined) return; // Disabled this line of code due to account chart not triggering afterSetExtremes on initial load but this might cause additional data requests
      const { chart } = e.target;
      chart.showLoading("Laddar…");

      const startDate = e.min
        ? format(new Date(e.min), "yyyy-MM-dd")
        : format(new Date("2000/01/01"), "yyyy-MM-dd");
      const endDate = e.max
        ? format(new Date(e.max), "yyyy-MM-dd")
        : format(new Date(), "yyyy-MM-dd");

      const { axiosInstance, axiosController } = initAxios("private");
      axiosGlobalController.current = axiosController;

      const chartRequested = legendItemClicked.current;
      const requests = [
        {
          id: "account",
          promise:
            chartRequested === "account"
              ? axiosInstance.get(
                  `/performance/data/${startDate}/${endDate}/${accountId}`
                )
              : Promise.resolve({ data: {} })
        },
        {
          id: "benchmark",
          promise:
            showBenchmarkChart && chartRequested === "benchmark"
              ? axiosInstance.get(
                  `/performance/data/${startDate}/${endDate}/${benchmarkId}/defaultbenchmark`
                )
              : Promise.resolve({ data: {} })
        }
      ];

      // Create an array of just the promises
      const promises = requests.map(req => req.promise);

      Promise.all(promises)
        .then(responses => {
          // Map responses to their corresponding identifiers
          const results = requests.map((req, index) => ({
            id: req.id,
            response: responses[index]
          }));

          // Map results to an object with the identifiers as keys
          const mappedResults = results.reduce((acc, { id, response }) => {
            acc[id] = response.data;
            return acc;
          }, {});

          let { account, benchmark } = mappedResults;

          let accountChartData = account?.main;
          let marketValueData = account?.marketValue;
          const benchmarkChartData = benchmark?.main;

          if (accountChartData) {
            // Filter accountChartData to remove any data points with a value < firstTransactionDate CAR-100
            accountChartData = accountChartData.filter(
              dataPoint => dataPoint[0] >= firstTransactionDate
            );
            // Filter marketValueData to remove any data points with a value < firstTransactionDate CAR-100
            marketValueData = marketValueData.filter(
              dataPoint => dataPoint[0] >= firstTransactionDate
            );

            chart.series[0].setData(accountChartData);
            // also set the current options series (re-render fix)
            options.current.series[0].data = accountChartData;
            if (!hasSpecialAccounts)
              options.current.series[1].data = marketValueData;

            // set coordinates for deposit flags
            if (hasDeposits) {
              const seriesKey = showBenchmarkChart
                ? 6
                : !hasSpecialAccounts
                ? 2
                : 1;
              const depositsSeriesData = [];
              Object.entries(movements.deposits).forEach(deposit => {
                const date = deposit[0];
                const value = deposit[1];

                const flagCoords = findInArrayByIndexValue(
                  accountChartData,
                  0,
                  Number(date)
                );

                if (flagCoords.length > 0) {
                  depositsSeriesData.push({
                    x: flagCoords[0][0],
                    y: flagCoords[0][1],
                    amount: convertToCurrency(value),
                    title: "&nbsp;I&nbsp;"
                  });
                }
              });
              chart.series[seriesKey]?.setData(depositsSeriesData);
            }

            // set coordinates for withdraws flags
            if (hasWithdraws) {
              const seriesKey = showBenchmarkChart
                ? 6 + (hasDeposits ? 1 : 0)
                : !hasSpecialAccounts
                ? 2 + (hasDeposits ? 1 : 0)
                : 1 + (hasDeposits ? 1 : 0);
              const withdrawsSeriesData = [];

              Object.entries(movements.withdraws).forEach(withdraw => {
                const date = withdraw[0];
                const value = withdraw[1];
                const flagCoords = findInArrayByIndexValue(
                  accountChartData,
                  0,
                  Number(date)
                );

                if (flagCoords.length > 0) {
                  withdrawsSeriesData.push({
                    x: flagCoords[0][0],
                    y: flagCoords[0][1],
                    amount: convertToCurrency(value),
                    title: "&nbsp;U&nbsp;"
                  });
                }
              });
              chart.series[seriesKey]?.setData(withdrawsSeriesData);
            }

            // Find the min and max values of the account chart and set those values on the yAxis
            const accountDataMin = Math.min(
              ...accountChartData.map(point => point[1])
            );
            const accountDataMax = Math.max(
              ...accountChartData.map(point => point[1])
            );
            const minMaxThreshold = (accountDataMax - accountDataMin) * 0.15;
            chart.yAxis[0].update({
              height: "75%",
              min: accountDataMin - minMaxThreshold,
              max: accountDataMax + minMaxThreshold
            });
            chart.yAxis[1].update({
              height: "25%",
              top: "75%"
            });
          }

          if (benchmarkChartData) {
            chart.series[2].setData(benchmarkChartData);
            options.current.series[2].data = benchmarkChartData;

            // set increase X points flags
            const increaseXdataPoints = findIncreaseXPoints(
              benchmarkChartData,
              200
            );
            const increaseXDataSeries = [];
            increaseXdataPoints.forEach((point, index) => {
              const xIncrease = 2 * (index + 1);
              const title = `${xIncrease}x`;
              increaseXDataSeries.push({
                x: point[0],
                y: point[1],
                title,
                amount: roundNumber(point[1], 2)
              });
            });
            chart.series[5].setData(increaseXDataSeries);

            // set the flag coordinates in the benchmark chart for the start of the mainChart
            if (accountDataAll?.length) {
              const flagCoords = findChartCoordsByDate(
                benchmarkChartData,
                flagDate.current
              );

              if (flagCoords?.length > 0) {
                options.current.series[4].data[0].x = flagCoords[0];
                options.current.series[4].data[0].y = 0; // Put the flag on the base of the chart
                chart.series[4].setData(options.current.series[4].data);
              }
            }

            // Find the min and max values of the benchmark chart and set those values on the yAxis
            const benchmarkDataMin = Math.min(
              ...benchmarkChartData.map(point => point[1])
            );
            const benchmarkDataMax = Math.max(
              ...benchmarkChartData.map(point => point[1])
            );
            const minMaxThreshold =
              (benchmarkDataMax - benchmarkDataMin) * 0.15;
            chart.yAxis[0].update({
              height: "100%",
              min: benchmarkDataMin - minMaxThreshold,
              max: benchmarkDataMax + minMaxThreshold
            });
            chart.yAxis[1].update({
              height: "0%",
              top: "100%"
            });
          }

          // legendItemClicked.current = null;
          chart.hideLoading();
        })
        .catch(function (err) {
          errorHandler.serverError(err);
        });
    },
    [
      initAxios,
      accountId,
      showBenchmarkChart,
      benchmarkId,
      hasSpecialAccounts,
      hasDeposits,
      hasWithdraws,
      movements,
      firstTransactionDate,
      errorHandler
    ]
  );

  // MARK: onLegendItemClick
  const onLegendItemClick = useCallback(
    function (e) {
      e.preventDefault();
      const thisSeries = this,
        thisSeriesId = thisSeries.userOptions.id,
        chart = this.chart;

      if (thisSeries.visible === true) return; // Do nothing if the series is already visible

      legendItemClicked.current = thisSeriesId;
      const isHybridButtonClick = thisSeriesId === "hybrid";
      const isAccountButtonClick = thisSeriesId === "account";
      const isBenchmarkButtonClick = thisSeriesId === "benchmark";

      const seriesToShow = [thisSeries];
      const seriesToHide = [];

      chart.series.forEach(serie => {
        const serieId = serie.userOptions.id;
        const isChartStartFlag = serieId === "flag";
        const isIncreaseXFlag =
          serie.userOptions.customType === "increasePoint";
        const isMarketValue = serieId === "marketValue";
        const isMovementsFlag = serie.userOptions.customType === "movementFlag";

        // Show marketValue + deposit/withdraws flags chart if we switch to "Er utveckling" chart
        // Show start flag if we switch to "Utveckling" or "Modellportfölj" charts
        if (
          (isAccountButtonClick && (isMarketValue || isMovementsFlag)) ||
          (isBenchmarkButtonClick && (isChartStartFlag || isIncreaseXFlag)) ||
          (isHybridButtonClick && (isMarketValue || isChartStartFlag))
        ) {
          seriesToShow.push(serie);
        } else if (serie !== thisSeries) {
          seriesToHide.push(serie);
        }
      });

      chart.update({
        series: chart.series.map(serie => ({
          visible: seriesToShow.includes(serie)
        }))
      });

      // HYBRID CHART
      if (thisSeriesId === "hybrid") {
        // Show hybrid chart label
        totalReturnsBox.current = setTotalReturnsBox(chart);

        // Reset the selected range to Allt and trigger afterSetExtremes
        chart.rangeSelector.clickButton(4, true);

        // Disable navigator series and range selector
        chart.update({
          navigator: {
            enabled: false
          },
          rangeSelector: {
            enabled: false
          }
        });

        const hybridDataMax = Math.max(
          ...hybridDataAll.current.map(point => point[1])
        );
        chart.yAxis[0].update({
          height: "80%",
          min: 0,
          max: hybridDataMax
        });
        chart.yAxis[1].update({
          minRange: 250,
          height: "20%",
          top: "80%",
          offset: 0,
          lineWidth: 0
        });
        toggleWaterMark(chart, "show", false);
      }
      // BENCHMARK CHART
      if (thisSeriesId === "benchmark") {
        // Remove hybrid chart label
        if (totalReturnsBox.current) {
          totalReturnsBox.current.destroy();
          totalReturnsBox.current = null;
        }

        // Enable navigator series and range selector
        chart.update({
          navigator: {
            enabled: true
          },
          rangeSelector: {
            enabled: true
            // selected: 4
          }
        });
        // Remove MarketValue.watermark
        toggleWaterMark(chart, "hide");

        chart.navigator.series[0].show();
        chart.navigator.series[0].setData([...benchmarkDataAll.current], true); // !! always use a reference to the original data

        // Update the yAxis
        chart.yAxis[0].update({
          height: "100%",
          min: benchmarkDataExtremes.current.min,
          max: benchmarkDataExtremes.current.max
        });
        chart.yAxis[1].update({
          height: "0%",
          top: "100%"
        });

        // Reset the selected range to Allt and trigger afterSetExtremes
        chart.rangeSelector.clickButton(4, true);
      }
      // ACCOUNT CHART
      if (thisSeriesId === "account") {
        // Remove hybrid chart label
        if (totalReturnsBox.current) {
          totalReturnsBox.current.destroy();
          totalReturnsBox.current = null;
        }

        // Enable navigator series and range selector
        chart.update({
          navigator: {
            enabled: true
          },
          rangeSelector: {
            enabled: true
            // selected: 4
          }
        });

        // Remove MarketValue.watermark
        toggleWaterMark(chart, "hide");

        chart.navigator.series[0].show();
        chart.navigator.series[0].setData([...accountDataAll.current], true); // !! always use a reference to the original data

        // Update the yAxis
        chart.yAxis[0].update({
          height: "75%",
          min: accountDataExtremes.current.min,
          max: accountDataExtremes.current.max
        });
        chart.yAxis[1].update({
          height: "25%",
          top: "75%"
        });

        // Reset the selected range to Allt and trigger afterSetExtremes
        chart.rangeSelector.clickButton(4, true);
      }
    },
    [setTotalReturnsBox, toggleWaterMark]
  );

  const options = useRef(
    setPerfChartOptions({
      isLargeDesktop,
      isDesktop,
      accountChartHasData,
      showBenchmarkChart,
      hasSpecialAccounts,
      hasDeposits,
      hasWithdraws,
      setSelectedRange,
      onChartLoad,
      onRender,
      afterSetExtremes,
      onLegendItemClick
    })
  );

  // MARK: useEffect
  useEffect(() => {
    if (!state.loaded && !mounted.current) {
      // Filter chartData.main.current to remove any data points with a value < firstTransactionDate CAR-100
      const chartDataMain =
        benchmarkChartData.hybrid?.length > 0
          ? benchmarkChartData.hybrid
          : chartData.main;
      if (chartDataMain) {
        accountData.current = chartDataMain.filter(
          dataPoint => dataPoint[0] >= firstTransactionDate
        );
        accountDataAll.current = accountData.current;

        // set current chart series
        options.current.series[0].data = accountData.current;
      }

      // set current navigator series
      if (!showBenchmarkChart)
        options.current.navigator.series.data = accountDataAll.current;

      if (showBenchmarkChart && benchmarkChartData.main) {
        benchmarkData.current = benchmarkChartData.main;
        // set benchmark chart series
        benchmarkDataAll.current = benchmarkData.current;
        // Find the min and max values of the benchmark chart
        const benchmarkDataMin = Math.min(
          ...benchmarkDataAll.current.map(point => point[1])
        );
        const benchmarkDataMax = Math.max(
          ...benchmarkDataAll.current.map(point => point[1])
        );
        const benchMinMaxThreshold =
          (benchmarkDataMax - benchmarkDataMin) * 0.15;
        // Store the min and max values of the benchmark chart
        benchmarkDataExtremes.current = {
          min: benchmarkDataMin - benchMinMaxThreshold,
          max: benchmarkDataMax + benchMinMaxThreshold
        };

        accountDataAll.current = benchmarkChartData.refAccount;
        // Find the min and max values of the account
        const accountDataMin = Math.min(
          ...accountDataAll.current.map(point => point[1])
        );
        const accountDataMax = Math.max(
          ...accountDataAll.current.map(point => point[1])
        );
        const accMinMaxThreshold = (accountDataMax - accountDataMin) * 0.15;
        // Store the min and max values of the account chart
        accountDataExtremes.current = {
          min: accountDataMin - accMinMaxThreshold,
          max: accountDataMax + accMinMaxThreshold
        };

        // set current chart series
        options.current.series[0].data = accountDataAll.current; // Setting the data here to avoid fetching on the first view of the chart
        options.current.series[2].data = benchmarkData.current;

        hybridData.current = benchmarkChartData.hybrid;
        // set hybrid chart series
        hybridDataAll.current = hybridData.current;
        options.current.series[3].data = hybridData.current;

        //hide navigator series and range selector
        options.current.navigator.enabled = false;
        options.current.rangeSelector.enabled = false;

        // set the flag series data to the benchmarkData element where the date matches the firstTransactionDate
        const flagCoords = findInArrayByIndexValue(
          benchmarkData.current,
          0,
          firstTransactionDate
        );

        if (flagCoords.length > 0) {
          flagDate.current = flagCoords[0][0];
          options.current.series[4].data[0].x = flagCoords[0][0];
          options.current.series[4].data[0].y = 0;
        }
        // }

        // MARK: Zones
        options.current.series[3].zones = [
          {
            value: flagDate.current,
            color: "#1ECBAA"
          },
          {
            color: "#4885B2"
          }
        ];

        // Navigator series is not used in the hybrid chart
        options.current.navigator.series.data = [];

        let totalAccountReturn = 0;
        let totalBenchmarkReturn = 0;
        if (benchmarkChartData.refAccount.length > 0) {
          totalAccountReturn =
            benchmarkChartData.refAccount[
              benchmarkChartData.refAccount.length - 1
            ][1];
          totalBenchmarkReturn =
            benchmarkDataAll.current[benchmarkData.current.length - 1][1];
        }

        // MARK: Total Returns Box Content
        returnsBoxContent.current = `
            <div style="color: #000; font-size: ${
              isMobileOrTablet ? "10px" : "12px"
            };">
              <strong>Totalavkastning</strong><br>
              Er Portfölj: ${totalAccountReturn.toFixed(2)} %<br>
              Modellportfölj: ${totalBenchmarkReturn.toFixed(2)} %
            </div>
          `;
      }

      // MARK: D/W Flags
      // Note: movements can be outside the chart range thus not pushing the flag coordinates to the series
      if (accountDataAll.current.length > 0) {
        if (movements && Object.keys(movements.deposits).length > 0) {
          const seriesKey = showBenchmarkChart
            ? 6
            : !hasSpecialAccounts
            ? 2
            : 1;
          Object.entries(movements.deposits).forEach(deposit => {
            const date = deposit[0];
            const value = deposit[1];

            const flagCoords = findInArrayByIndexValue(
              accountDataAll.current,
              0,
              Number(date)
            );

            if (flagCoords.length > 0) {
              options.current.series[seriesKey]?.data.push({
                x: flagCoords[0][0],
                y: flagCoords[0][1],
                amount: convertToCurrency(value),
                title: "&nbsp;I&nbsp;"
              });
            }
          });
        }

        // Withdraw flags
        // Note: movements can be outside the chart range thus not pushing the flag coordinates to the series
        if (movements && Object.keys(movements.withdraws).length > 0) {
          const seriesKey = showBenchmarkChart
            ? 6 + (hasDeposits ? 1 : 0)
            : !hasSpecialAccounts
            ? 2 + (hasDeposits ? 1 : 0)
            : 1 + (hasDeposits ? 1 : 0);

          Object.entries(movements.withdraws).forEach(withdraw => {
            const date = withdraw[0];
            const value = withdraw[1];
            const flagCoords = findInArrayByIndexValue(
              accountDataAll.current,
              0,
              Number(date)
            );

            if (flagCoords.length > 0) {
              options.current.series[seriesKey]?.data.push({
                x: flagCoords[0][0],
                y: flagCoords[0][1],
                amount: convertToCurrency(value),
                title: "&nbsp;U&nbsp;"
              });
            }
          });
        }
      }

      const marketValueData =
        chartData?.marketValue || benchmarkChartData?.marketValue;

      if (!hasSpecialAccounts && marketValueData)
        // Set and filter chartData.marketValue to remove any data points with a value < firstTransactionDate CAR-100
        options.current.series[1].data = marketValueData.filter(
          dataPoint => dataPoint[0] >= firstTransactionDate
        );

      mounted.current = true;
      setState({ loaded: true });
    }

    return () => axiosGlobalController.current?.abort();
  }, [
    state.loaded,
    chartData,
    benchmarkChartData,
    firstTransactionDate,
    showBenchmarkChart,
    hasSpecialAccounts,
    movements,
    hasDeposits,
    isMobileOrTablet
  ]);

  return !state.loaded ? (
    <Box
      sx={{
        height: "80%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <CircularProgress />
    </Box>
  ) : (
    <HighchartsReact
      highcharts={Highcharts}
      allowChartUpdate={false}
      constructorType={"stockChart"}
      options={options.current}
      containerProps={{
        style: {
          position: "relative",
          top: "-35px"
        }
      }}
    />
  );
};

export default PerformanceChart;

// Helper function to find the element in the data array that matches the target date
const findChartCoordsByDate = (data, targetDate) => {
  // Loop through the data array
  for (var i = 0; i < data.length; i++) {
    // Check if the x value (timestamp) matches the target date
    if (data[i][0] === targetDate) {
      // Return the matching element
      return data[i];
    }
  }
  // If no matching element is found, return null
  return null;
};

// Helper function to find the points where the y value has increased by a target amount
const findIncreaseXPoints = (dataPoints, targetIncrease) => {
  const initialValue = dataPoints[0][1]; // Get the first y value
  let currenttarget = targetIncrease;
  const results = [];

  for (let i = 1; i < dataPoints.length; i++) {
    const currentValue = dataPoints[i][1];
    const difference = currentValue - initialValue;

    // If the difference exceeds the current target, capture the point
    if (difference >= currenttarget) {
      results.push([dataPoints[i][0], currentValue]); // Push time and y value
      currenttarget += targetIncrease; // increase the target for the next threshold (multiples of targetIncrease)
    }
  }

  return results;
};

PerformanceChart.propTypes = {
  chartData: PropTypes.object,
  benchmarkChartData: PropTypes.object,
  firstTransactionDate: PropTypes.number,
  movements: PropTypes.object,
  accountId: PropTypes.string,
  benchmarkId: PropTypes.string
};
